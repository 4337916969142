<template>
  <div
    class="w-6 p-2 flex align-items-center justify-content-center border-round-lg mt-5"
  >
    <img
      src="@/assets/ConceptImgAlt.png"
      alt="Concept de l'application expliqué"
      class="w-full"
    />
  </div>
  <div class="p-3 w-full">
    <Fieldset
      legend="Vidéos à réviser"
      :toggleable="true"
      class="w-full flex justify-content-between"
    >
      <div class="w-full flex flex-wrap gap-2">
        <Card
          v-for="vid in videos"
          :key="vid.id"
          class="w-15 cursor-pointer bc_feel_blue"
          @click="openQuiz(vid)"
        >
          <template #header>
            <Image :src="vid.miniature" :alt="vid.alt" imageClass="vidImg" />
          </template>
          <template #content>
            <div class="w-full flex flex-column justify-content-center">
              <span class="poppins text-center"
                >Score : {{ calculScore(vid.score, vid.quizPartList.length) }} /
                {{ vid.quizPartList.length }}</span
              >
              <ProgressBar :value="vid.score"></ProgressBar>
            </div>
          </template>
        </Card>
      </div>
    </Fieldset>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      videos: this.$store.getters.getVideos,
      timerMS: 5000,
      vidPerPage: 1,
      vidNum: 1,
      user: {
        score: 0,
      },
    };
  },
  watch: {
    "$store.state.videosList": function () {
      this.videos = this.$store.getters.getVideos;
    },
  },
  mounted() {
    if (
      JSON.parse(localStorage.getItem("vuex")) === undefined ||
      JSON.parse(localStorage.getItem("vuex")) === null
    ) {
      this.getVideos();
    }
  },
  methods: {
    openQuiz(vid) {
      this.$store.commit("selectQuiz", vid);
      this.$router.push("/quiz");
    },
    calculScore(score, len) {
      return Math.round((score * len) / 100);
    },
    getVideos() {
      axios
        .get(process.env.VUE_APP_API + "/quiz/list")
        .then((res) => {
          let data = res.data;
          data.forEach((el) => {
            el.miniature = process.env.VUE_APP_API + `/img?id=${el.id}`;
          });
          this.$store.commit("addVidList", data);
        });
    },
  },
};
</script>

<style>
.vidImg {
  height: 15vh !important;
  width: 15vw !important;
  object-fit: scale-down !important;
}

.p-card-body {
  width: -webkit-fill-available !important;
}

.bc_feel_blue {
  border: 3px solid #0048f2;
}
</style>
