<template>
  <div
    class="surface-overlay shadow-2 flex relative lg:static justify-content-between w-full"
    style="min-height: 5vh"
  >
    <a
      v-ripple
      class="cursor-pointer inline-flex align-items-center px-3 lg:hidden text-700 p-ripple"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-bars text-2xl"></i>
    </a>
    <div
      class="hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
    >
      <ul class="flex list-none p-0 m-0 flex-column lg:flex-row">
        <li class="h-full">
          <a
            v-ripple
            href="/"
            class="cursor-pointer no-underline h-full inline-flex align-items-center text-blue-700 border-left-2 lg:border-left-none lg:border-bottom-2 border-blue-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3 p-ripple"
          >
            <span class="pi pi-home mr-2"></span>
            <span class="font-medium">Accueil</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="w-3 h-full flex justify-content-center py-1">
      <img src="../../public/img/icons/favicon.png" alt="LogoFeelEdu" class="h-2rem cursor-pointer" href="/">
    </div>

    <a
      v-ripple
      class="cursor-pointer inline-flex align-items-center px-3 lg:hidden text-700 p-ripple"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v text-xl"></i>
    </a>
    <div
      class="hidden lg:flex lg:justify-content-end absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
    >
      <ul class="flex list-none p-0 m-0 flex-column lg:flex-row">
        <li class="h-full">
          <a
            v-ripple
            class="cursor-pointer h-full inline-flex align-items-center text-blue-700 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-ripple"
            href="https://www.tiktok.com/@feel.edu"
          >
          <font-awesome-icon icon="fa-brands fa-tiktok" size="lg" />
            <span class="font-medium inline lg:hidden">TikTok</span>
          </a>
        </li>
        <li class="h-full">
          <a
            v-ripple
            class="cursor-pointer h-full inline-flex align-items-center text-blue-700 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-ripple"
            href="https://www.instagram.com/feel.eu/?igshid=YmMyMTA2M2Y%3D"
          >
          <font-awesome-icon icon="fa-brands fa-instagram" size="xl" />
            <span class="font-medium inline lg:hidden">Instagram</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.signout {
  padding: 0.375rem !important;
}
</style>
