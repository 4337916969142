<template>
  <div class="flex w-full h-fit">
    <QuizVideo />
  </div>
</template>

<script>
import QuizVideo from '@/components/QuizVideo.vue';

export default { components: { QuizVideo } };
</script>

<style></style>
