<template>
  <div class="w-full h-fit flex justify-content-center align-items-center">
    <div class="flex flex-column align-items-center w-full">
      <Card class="w-9">
        <template #content>
          <video
            class="w-full h-30rem"
            id="quizVid"
            :src="video"
            :key="video"
            autoplay
            controls
          ></video>
        </template>
      </Card>
      <div
        class="w-full flex justify-content-center gap-3 mt-5"
        v-if="vidNum !== quizLen + 1"
      >
        <ToggleButton
          v-for="(q, index) in quiz"
          :key="q"
          v-model="q.checked"
          :onLabel="q.text"
          :offLabel="q.text"
          class="w-15rem"
          @click="nextQuestion(index)"
        />
      </div>
      <div
        class="w-full flex justify-content-center gap-3 mt-5 transition-duration-500 w-full"
        v-else
      >
        <div class="h-fit flex flex-column justify-content-center">
          <Button
            icon="pi pi-home"
            severity="info"
            @click="this.$router.push('/')"
          />
        </div>
        <div class="flex align-items-center justify-content-center w-5">
          <span class="mr-3">Voici ton résultat :</span>

          <Rating
            v-model="user.score"
            class="flex-wrap"
            :cancel="false"
            readonly
            :stars="quizLen"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      vidSelected: "",
      video: "",
      quiz: "",
      vidNum: 1,
      quizLen: 0,
      user: {
        score: 0,
      },
      quizChosen: "",
      quizDone: false,
      visible: false,
    };
  },
  mounted() {
    let vid = this.$store.getters.getSelectedVideo;

    if (vid.length !== 0) {
      let vidInit =
        process.env.VUE_APP_API +
        "/quiz?vidNum=" +
        this.vidNum +
        "&id=" +
        vid.id;
      this.$store.commit("nextVid", vidInit);
      this.video = this.$store.getters.getVidUrl;

      axios
        .get(process.env.VUE_APP_API + "/quiz/part", {
          params: { vidNum: this.vidNum, id: vid.id },
          headers: {
            "Access-Control-Allow-Origin": "https://feel-edu.fr:5001",
          },
        })
        .then((res) => {
          let quiz = res.data;
          this.quiz = quiz[0];
        });
      this.quizChosen = vid;
      this.quizLen = this.quizChosen.quizPartList.length;
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    nextQuestion(i) {
      let vid = this.quizChosen;

      // traitement bonnes réponses
      if (this.quiz[i].isAnswer === true) {
        this.user.score += 1;
        this.$store.commit("updScrVid", {
          score: this.user.score,
          vidID: this.quizChosen.id,
        });
      }

      // Changement de vidéo tant que nous avons pas atteint la dernière partie
      if (vid.quizPartList.length >= this.vidNum) {
        this.vidNum += 1;
        let newVid =
          process.env.VUE_APP_API +
          "/quiz?vidNum=" +
          this.vidNum +
          "&id=" +
          vid.id;
        this.$store.commit("nextVid", newVid);
        this.video = this.$store.getters.getVidUrl;

        // Requête HTTP pour récupérer les réponses de la partie de quiz
        axios
          .get(process.env.VUE_APP_API + "/quiz/part", {
            params: { vidNum: this.vidNum, id: vid.id },
            headers: {
              "Access-Control-Allow-Origin": "https://feel-edu.fr:5001",
            },
          })
          .then((res) => {
            let quiz = res.data;
            this.quiz = quiz[0];
          });
      }
    },
  },
};
</script>
