import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

export default createStore({
  state: {
    videosList: [],
    quizObj: "",
    vidUrl: "",
  },
  getters: {
    getVideos: (state) => {
      return state.videosList;
    },
    getVidUrl: (state) => {
      return state.vidUrl;
    },
    getSelectedVideo: (state) => {
      return state.quizObj;
    },
  },
  mutations: {
    selectQuiz(state, quiz) {
      state.quizObj = quiz;
    },
    nextVid(state, newVid) {
      state.vidUrl = newVid;
    },
    updScrVid(state, data) {
      let vidFound = state.videosList.find((vid) => vid.id === data.vidID);
      let percentage = Math.round(
        (data.score / vidFound.quizPartList.length) * 100
      );

      vidFound.score = percentage;
    },
    addVidList(state, list) {
      state.videosList = list;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    new VuexPersistence({
      reducer: (state) => ({ videosList: state.videosList }),
    }).plugin,
  ],
});
