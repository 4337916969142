import HomePage from './views/HomePage.vue';

<template>
  <HomePage />
</template>

<script>
import HomePage from "./views/HomePage.vue";

export default {
  components: { HomePage },
};
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/poppins");

.poppins {
  font-family: "Poppins" !important;
}

body {
  margin: 0 !important;
  /* background-color: #0048f2; */
}

#app {
  font-family: "Poppins", sans-serif !important;
}
</style>
