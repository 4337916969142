import { createRouter, createWebHistory } from "vue-router";
import ListVideos from "@/components/ListVideos.vue";
import QuizVideo from "@/views/QuizPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: ListVideos,
    beforeEnter: (to, from, next) => {
      document.title = "FeelEdu - Accueil"
      next()
    }
  },
  {
    path: "/quiz",
    name: "quiz",
    component: QuizVideo,
    beforeEnter: (to, from, next) => {
      document.title = "FeelEdu - Quiz"
      next()
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
