<template>
  <div
    class="flex w-full allHeight flex-column align-items-center justify-content-center"
  >
    <div class="sticky top-0 w-full p-0 mb-3">
      <NavBar />
    </div>
    <div class="allHeight w-full flex flex-column align-items-center">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default { components: { NavBar } };
</script>

<style>
.allHeight {
  height: 100vh !important;
}

.bg-feel {
  background-color: #0048f2 !important;
}
</style>
